/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-12 13:37:30
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import {
transport } from
'@/utils/options';
export var models = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'name',
    itemProps: {
      label: '样本名称：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 20 } } },



  {
    span: 10,
    kname: 'type',
    itemProps: {
      label: '样本类型：',
      rules: [genRules('require')],
      prop: 'type' } },


  {
    span: 8,
    kname: 'species',
    itemProps: {
      label: '物种：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 20 } } },



  {
    span: 8,
    kname: 'quantity',
    itemProps: {
      label: '送样量：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 10 } } },



  {
    span: 8,
    kname: 'transport',
    itemProps: {
      label: '运输方式：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        list: transport,
        clearable: true } } },



  {
    kname: 'comment',
    itemProps: {
      label: '备注：' },

    component: {
      name: 'el-input',
      props: {
        type: 'textarea',
        maxlength: 50 } } }];});





// form 默认值
export var formData = function formData() {return {};};